import dayjs from "dayjs";
import { gql } from "graphql-request";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import { useBaseDate, useFilingsStore } from "@/stores/filingsStore";

import type { T_RESPONSE_HONGKONG_FILINGS } from "@/types/model/response/HongkongFilingType";
import createGraphqlClient from "@/utils/createGraphqlClient";
import parseHongkongFiling from "@/utils/parser/parseHongkongFiling";

const query = (baseDate: string, secCode?: string, page = 1) => gql`
  query HONGKONG {
    getDisclosure(
      filter: {
        exchangeType: HONGKONG
        ${secCode ? `securityCode: "${secCode}"` : ""}
        limit: 20
        page: ${page}
        timeRange: { endDate: "${baseDate}", rangeLength: 1, rangeLengthUnit: Y }
      }
    ) {
      id
      dataDate
      korName
      details {
        ... on Hongkong {
          secCode
          categoryId
          fileLink
          fileType
        }
      }
      analysisDetails {
        topicKor
        summarizeTinyKor
        summarizeLongKor
      }
    }
  }
`;

const useHongKongFilingList = (exchange: string, secCode?: string) => {
  const baseDate = useBaseDate((state) =>
    dayjs(state.baseDate).format("YYYY-MM-DD")
  );

  const setFilings = useFilingsStore((state) => state.setFilings);

  const client = createGraphqlClient();

  const {
    isLoading,
    isError,
    isSuccess,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [exchange, secCode, baseDate],
    async ({ pageParam = 1 }) => {
      const response = await client.request<T_RESPONSE_HONGKONG_FILINGS>(
        query(baseDate, secCode, pageParam)
      );
      const parsedData = parseHongkongFiling(response);
      return {
        filings: parsedData,
        nextPage: parsedData.length > 0 ? pageParam + 1 : undefined,
      };
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      enabled: true,
      refetchOnWindowFocus: false,
      networkMode: "always",
      onSuccess: (data) => {
        const allFilings = data.pages.flatMap((page) => page.filings);
        setFilings(allFilings);
      },
    }
  );

  const isReloading = isFetching && isSuccess && !isFetchingNextPage;

  return {
    isLoading,
    isError,
    isReloading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default useHongKongFilingList;

import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { gql } from "graphql-request";

import { useBaseDate, useFilingsStore } from "@/stores/filingsStore";

import type { T_RESPONSE_USA_FILINGS } from "@/types/model/response/USAFilingType";
import createGraphqlClient from "@/utils/createGraphqlClient";
import parseUSAFiling from "@/utils/parser/parseUSAFiling";

const query = (
  baseDate: string,
  isIncludeInsider: boolean,
  secCode?: string | number,
  page = 1
) => gql`
  query USA {
    getDisclosure(
      filter: {
        exchangeType: USA
        ${secCode ? `securityCode: "${secCode}"` : ""}
        limit: 20
        page: ${page}
        timeRange: { endDate: "${baseDate}", rangeLength: 1, rangeLengthUnit: Y }
        isIssuer: ${!isIncludeInsider}
      }
    ) {
      id
      dataDate
      korName
      details {
        ... on USA {
          ticker
          mainUrl
          form
          eventLabel
          exchangeLabel
          analysisDEF14A {
            proposalKorList
          }
          analysisForm10s {
            businessOverviewKor
            businessOverviewShortKor
            riskJSONKor
            legalJSONKor
            repurchaseKor
            financeEarningKor
            financeLiquidityKor
            financeEquityKor
            financeOthersKor
          }
        }
      }
      analysisDetails {
        topicKor
        summarizeTinyKor
        summarizeLongKor
      }
    }
  }
`;

const useUSAFilingList = (
  exchange: string,
  isIncludeInsider: boolean,
  secCode?: string
) => {
  const baseDate = useBaseDate((state) =>
    dayjs(state.baseDate).format("YYYY-MM-DD")
  );

  const setFilings = useFilingsStore((state) => state.setFilings);

  const client = createGraphqlClient();

  const {
    isLoading,
    isError,
    isSuccess,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [exchange, secCode, isIncludeInsider, baseDate],
    async ({ pageParam = 1 }) => {
      const response = await client.request<T_RESPONSE_USA_FILINGS>(
        query(baseDate, isIncludeInsider, secCode, pageParam)
      );
      const parsedData = parseUSAFiling(response);
      return {
        filings: parsedData,
        nextPage: parsedData.length > 0 ? pageParam + 1 : undefined,
      };
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      enabled: true,
      refetchOnWindowFocus: false,
      networkMode: "always",
      onSuccess: (data) => {
        const allFilings = data.pages.flatMap((page) => page.filings);
        setFilings(allFilings);
      },
    }
  );

  const isReloading = isFetching && isSuccess && !isFetchingNextPage;

  return {
    isLoading,
    isError,
    isReloading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default useUSAFilingList;
